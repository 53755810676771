'use client'

import { Button, Spacer, Text } from '@vinted/web-ui'

import { useTranslate } from '@marketplace-web/shared/i18n'
import { onA11yKeyDown } from '@marketplace-web/shared/ui-helpers'
import { ClickableElement } from 'constants/tracking/clickable-elements'

import useAuthTracking from '../../../hooks/useAuthTracking'
import useAuthenticationContext from '../../../hooks/useAuthenticationContext'
import { AuthView } from '../../../constants'

const SelectTypeFooter = () => {
  const { authView, setAuthView, isAuthPage } = useAuthenticationContext()
  const isLoginView = authView === AuthView.SelectTypeLogin

  const selectType = isLoginView ? 'login' : 'register'
  const translate = useTranslate(`auth.select_type.${selectType}`)
  const actionsTranslate = useTranslate('auth.select_type.actions')
  const { trackClickEvent } = useAuthTracking()

  function handleSwitchContext() {
    if (isLoginView) {
      setAuthView(AuthView.SelectTypeRegister)
      trackClickEvent({ target: ClickableElement.SwitchToRegistration })

      return
    }

    setAuthView(AuthView.SelectTypeLogin)
    trackClickEvent({ target: ClickableElement.SwitchToLogin })
  }

  function proceedToEmailLogin() {
    setAuthView(AuthView.EmailLogin)
    trackClickEvent({ target: ClickableElement.SwitchToEmailLogin })
  }

  function proceedToEmailRegister() {
    setAuthView(AuthView.EmailRegister)
    trackClickEvent({ target: ClickableElement.SwitchToEmailRegistration })
  }

  function handleProceedLinkClick() {
    if (isLoginView) {
      proceedToEmailLogin()
    } else {
      proceedToEmailRegister()
    }
  }

  const renderEmailButton = () => {
    const testId = `auth-select-type--${selectType}-email`

    if (isAuthPage) {
      return (
        <Button
          theme="primary"
          styling={Button.Styling.Flat}
          onClick={handleProceedLinkClick}
          testId={testId}
        >
          {actionsTranslate('email')}
        </Button>
      )
    }

    return (
      <Text as="span" width={Text.Width.Parent} alignment={Text.Alignment.Center}>
        {translate('actions.proceed.title')}
        <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
        <span
          role="button"
          onKeyDown={onA11yKeyDown}
          className="u-cursor-pointer"
          tabIndex={0}
          onClick={handleProceedLinkClick}
          data-testid={testId}
        >
          <Text as="span" text={translate('actions.proceed.link')} theme="primary" underline />
        </span>
      </Text>
    )
  }

  return (
    <>
      {renderEmailButton()}
      <Spacer size={isAuthPage ? Spacer.Size.X2Large : Spacer.Size.Regular} />
      <Text as="span" width={Text.Width.Parent} alignment={Text.Alignment.Center}>
        {translate('actions.switch.title')}
        <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
        <span
          role="button"
          onClick={handleSwitchContext}
          onKeyDown={onA11yKeyDown}
          className="u-cursor-pointer"
          tabIndex={0}
          data-testid={`auth-select-type--${selectType}-switch`}
        >
          <Text as="span" text={translate('actions.switch.link')} theme="primary" underline />
        </span>
      </Text>
    </>
  )
}

export default SelectTypeFooter
