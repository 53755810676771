'use client'

import useRefUrl from 'hooks/useRefUrl'

import { BUSINESS_ACCOUNT_REGISTER_URL } from 'constants/routes'

import useAuthModal from '../useAuthModal/useAuthModal'

const useSuccessUrl = (defaultUrl?: string) => {
  const { isBusinessAuth } = useAuthModal()
  const refUrl = useRefUrl(defaultUrl)

  if (isBusinessAuth) return BUSINESS_ACCOUNT_REGISTER_URL

  return refUrl
}

export default useSuccessUrl
