export enum AppleIDLoginStatus {
  Success = 'AppleIDSignInOnSuccess',
  Failure = 'AppleIDSignInOnFailure',
}

export enum FacebookLoginStatus {
  Connected = 'connected',
}

export const FACEBOOK_PERMISSIONS = 'email'
