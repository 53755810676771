import { KeyboardEvent as ReactKeyboardEvent } from 'react'

import { KeyboardKey } from '@marketplace-web/shared/browser'

export const onA11yKeyDown = (event: KeyboardEvent | ReactKeyboardEvent, callback?: () => void) => {
  const { key } = event

  if (key !== KeyboardKey.Enter && key !== KeyboardKey.Spacebar) return

  if (callback) {
    callback()

    return
  }

  event.target?.dispatchEvent(new Event('click', { bubbles: true }))
}
