import { isInternalUrl, loadScript } from '@marketplace-web/shared/utils'

import { ROOT_URL } from 'constants/routes'

export const GOOGLE_SDK_LINK = 'https://apis.google.com/js/api:client.js'
export const GOOGLE_SDK_SCRIPT_ID = 'google-sdk'

const decodeBase64Object = <T>(base64String: string): T | undefined => {
  try {
    const decodedString = atob(base64String)

    return JSON.parse(decodedString)
  } catch {
    return undefined
  }
}

export const getGoogleRedirectUrl = (state: string) => {
  const decodedState = decodeBase64Object<{ redirect_uri: string; random_string: string }>(state)
  const decodedUrl = decodedState?.redirect_uri || state

  return isInternalUrl(decodedUrl) ? decodedUrl : ROOT_URL
}

export const loadGoogleSdk = () => {
  return loadScript({
    id: GOOGLE_SDK_SCRIPT_ID,
    isAsync: true,
    src: GOOGLE_SDK_LINK,
  })
}
