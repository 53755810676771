'use client'

import { useEffect, useState } from 'react'

import { getTwoFADetails } from 'data/api'
import { TwoFaDetailsModel } from 'types/models/authentication'
import { transformTwoFaDetails } from 'data/transformers/authentication'
import { VerificationType, TwoFactorVerification } from '@marketplace-web/domain/verification'
import { redirectToPage } from '@marketplace-web/shared/browser'
import { LOGIN_SELECT_TYPE } from 'constants/routes'

import useAuthenticationContext from '../../hooks/useAuthenticationContext'

const LoginTwoFactorVerification = () => {
  const [twoFactorLoginData, setTwoFactorLoginData] = useState<
    TwoFaDetailsModel & { refUrl: string }
  >()
  const [isVerifierEnabled, setIsVerifierEnabled] = useState(false)
  const { twoFactorLoginData: twoFactorErrorData, isAuthPage } = useAuthenticationContext()

  useEffect(() => {
    if (!twoFactorErrorData) return

    const fetchTwoFaLoginData = async (id: string) => {
      const response = await getTwoFADetails(id)

      if ('errors' in response) return

      setTwoFactorLoginData({
        ...transformTwoFaDetails(response),
        refUrl: twoFactorErrorData.refUrl,
      })
    }

    if ('id' in twoFactorErrorData) {
      fetchTwoFaLoginData(twoFactorErrorData.id)
      setIsVerifierEnabled(true)

      return
    }

    setTwoFactorLoginData(twoFactorErrorData)
  }, [twoFactorErrorData])

  if (!twoFactorErrorData) {
    redirectToPage(LOGIN_SELECT_TYPE)

    return null
  }

  if (!twoFactorLoginData) return null

  const { controlCode, nextResendAvailableIn, userMaskedInfo, showResendOption, refUrl } =
    twoFactorLoginData

  return (
    <div className="narrow-container u-background-white u-padding-bottom-x-large">
      <TwoFactorVerification
        controlCode={controlCode}
        refUrl={refUrl}
        verificationType={VerificationType.Login}
        maskedInfo={userMaskedInfo}
        nextResendAvailableIn={Number(nextResendAvailableIn)}
        showResend={Boolean(showResendOption)}
        // twoFAId is not used in login verification
        // TODO: Remove this prop when conditional props are added to TwoFactorVerification
        twoFAId={0}
        isVerifierEnabled={isVerifierEnabled}
        isAuthPage={isAuthPage}
      />
    </div>
  )
}

export default LoginTwoFactorVerification
