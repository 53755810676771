import { AxiosResponse } from 'axios'
import { get } from 'lodash'

import { AxiosErrorResponseData, Response, ResponseError } from '@marketplace-web/shared/api-client'

type DataDomeError = {
  url: string
}

export const isAxiosErrorBlockedByDataDome = <T extends AxiosErrorResponseData<unknown>>(
  headers: AxiosResponse<T>['headers'],
  data: AxiosResponse<T>['data'],
): data is T & DataDomeError => !!headers['x-dd-b'] && 'url' in data

export const isErrorBlockedByDataDome = <T extends Record<string, unknown>>(
  headers: Record<string, string>,
  data: T | string | undefined,
): data is T & DataDomeError => !!headers['x-dd-b'] && typeof data === 'object' && 'url' in data

export const isBlockedByDataDome = (response: Response<unknown> | ResponseError<unknown>) => {
  if (!('errors' in response)) return false
  if (response.status !== 403) return false

  return !!get(response, 'exception.response.headers.x-dd-b')
}
