'use client'

import { Button, Spacer, Text } from '@vinted/web-ui'
import { useForm } from 'react-hook-form'

import { getFingerprint } from '@marketplace-web/domain/audit'
import { useDataDomeCaptcha } from '@marketplace-web/domain/data-dome'
import { AuthenticateGrantType } from '@marketplace-web/shared/authentication'
import { navigateToPage, redirectToPage } from '@marketplace-web/shared/browser'
import { useTranslate } from '@marketplace-web/shared/i18n'
import {
  renderValidation,
  SeparatedList,
  useFormValidationMessage,
} from '@marketplace-web/shared/ui-helpers'

import FaqEntryUrl from 'components/FaqEntryUrl'
import { AccessChannel } from 'constants/access-channel'
import { FaqEntryType } from 'constants/faq-entry'
import { FORGOT_PASSWORD_URL, REGISTER_SELECT_TYPE, ROOT_URL } from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { authenticateUser } from 'data/api/authentication/requests'
import { ResponseCode } from 'data/api/response-codes'
import { transformAuthenticateUserError } from 'data/transformers/authentication'
import useRefUrl from 'hooks/useRefUrl'

import useAuthenticationContext from '../../hooks/useAuthenticationContext'
import useAuthTracking from '../../hooks/useAuthTracking'
import useSuccessUrl from '../../hooks/useSuccessUrl/useSuccessUrl'
import PasswordField from '../PasswordField'

type Fields = {
  password: string
}

const PasswordVerificationLogin = () => {
  const translate = useTranslate('user.wrong_email_in_registration')
  const refUrl = useRefUrl(ROOT_URL)
  const successUrl = useSuccessUrl()
  const {
    register,
    formState: { errors },
    setError,
    handleSubmit,
  } = useForm<Fields>()
  const getErrorMessage = useFormValidationMessage(errors)
  const { trackInputEvent, trackClickEvent } = useAuthTracking()
  const { handleViewTwoFactorLogin, externalRegisterData, isAuthPage } = useAuthenticationContext()

  const { idToken, email } = externalRegisterData || {}

  const doAuthenticate = async ({ password }: Fields) => {
    if (!email) return

    const fingerprint = await getFingerprint()

    const response = await authenticateUser({
      grantType: AuthenticateGrantType.Password,
      username: email,
      controlCode: idToken,
      password,
      fingerprint,
    })
    const is2FARequired =
      response.code === ResponseCode.Required2FA ||
      response.code === ResponseCode.VerifierSecondFactorRequired

    if (is2FARequired && 'payload' in response && response.payload) {
      handleViewTwoFactorLogin({
        ...transformAuthenticateUserError(response.payload),
        refUrl: successUrl,
      })

      return
    }

    if ('errors' in response) {
      setError('password', { type: 'manual', message: response.message })

      return
    }

    navigateToPage(refUrl)
  }

  useDataDomeCaptcha(() => {
    handleSubmit(doAuthenticate)()
  })

  if (!externalRegisterData) {
    redirectToPage(REGISTER_SELECT_TYPE)

    return null
  }

  const handleInputFocus = (target: string) => () => trackInputEvent({ target, state: 'focus' })

  const handleInputBlur = (target: string) => () => trackInputEvent({ target, state: 'unfocus' })

  const getInputEvents = (field: string) => {
    return {
      onFocus: handleInputFocus(field),
      onBlur: handleInputBlur(field),
    }
  }

  const handleClickTracking = (target: ClickableElement) => () => {
    trackClickEvent({ target })
  }

  const renderLink = ({
    url,
    text,
    eventTarget,
  }: {
    url: string
    text: string
    eventTarget: ClickableElement
  }) => (
    <Text as="span" width={Text.Width.Parent} alignment={Text.Alignment.Center}>
      <a
        className="u-disable-underline"
        href={url}
        target="_blank"
        rel="noreferrer"
        onClick={handleClickTracking(eventTarget)}
      >
        {text}
      </a>
    </Text>
  )

  const renderContactUsLink = (url: string) =>
    renderLink({ url, text: translate('contact_us'), eventTarget: ClickableElement.HavingTrouble })

  const renderPasswordResetLink = (url: string) =>
    renderLink({
      url,
      text: translate('reset_password'),
      eventTarget: ClickableElement.ForgotPassword,
    })

  return (
    <form onSubmit={handleSubmit(doAuthenticate)}>
      {!isAuthPage && <Spacer size={Spacer.Size.Large} />}
      <div className="u-ui-margin-horizontal-large">
        <Text
          as="h1"
          text={translate('heading', { registration_email: email })}
          alignment={isAuthPage ? Text.Alignment.Left : Text.Alignment.Center}
          width={Text.Width.Parent}
          type={Text.Type.Heading}
        />
      </div>
      {isAuthPage ? (
        <Spacer size={Spacer.Size.XLarge} />
      ) : (
        <>
          <Spacer size={Spacer.Size.X2Large} />
          <Spacer />
        </>
      )}
      <div className="u-ui-margin-horizontal-large">
        <Text
          text={translate('error_explanation', {
            registration_email: <Text key="registration-email" as="span" text={email} bold />,
          })}
          as="p"
        />
      </div>
      {isAuthPage && <Spacer />}
      <PasswordField
        {...register('password')}
        placeholder={translate('placeholder')}
        validation={renderValidation(getErrorMessage('password'))}
        {...getInputEvents('password')}
      />
      <Spacer size={isAuthPage ? Spacer.Size.Regular : Spacer.Size.Large} />
      <SeparatedList separator={<Spacer size={Spacer.Size.XLarge} />}>
        <div className="u-ui-margin-horizontal-large">
          <Button
            testId="login-button"
            type={Button.Type.Submit}
            text={translate('button', { registration_email: email })}
            styling={Button.Styling.Filled}
            onClick={handleClickTracking(ClickableElement.SocialLoginWithPassword)}
          />
        </div>
        {renderPasswordResetLink(FORGOT_PASSWORD_URL)}
        <FaqEntryUrl
          type={FaqEntryType.CantLogin}
          accessChannel={AccessChannel.ProductLink}
          render={url => renderContactUsLink(url)}
        />
      </SeparatedList>
      <Spacer size={Spacer.Size.XLarge} />
    </form>
  )
}

export default PasswordVerificationLogin
