'use client'

import { useCallback, useState } from 'react'

import { useDataDomeCaptcha } from '@marketplace-web/domain/data-dome'
import {
  GoogleOauthButton,
  OnGoogleLoginSuccess,
} from '@marketplace-web/domain/socials-authentication'
import { AuthenticateProvider } from '@marketplace-web/shared/authentication'
import { navigateToPage, useLocation } from '@marketplace-web/shared/browser'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { urlWithParams } from '@marketplace-web/shared/utils'

import { authenticateFailEvent, authenticateSuccessEvent } from '_libs/common/event-tracker/events'
import { GoogleTagManagerEvent } from 'constants/google'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { getCurrentUser } from 'data/api'
import { ResponseCode } from 'data/api/response-codes'
import { transformAuthenticateUserError } from 'data/transformers/authentication'
import { useGoogleTagManagerTrack } from '@marketplace-web/domain/google-track'

import { AuthExternalRegisterView } from '../../constants'
import useAuthenticationContext from '../../hooks/useAuthenticationContext'
import useAuthTracking from '../../hooks/useAuthTracking'
import useSocialLogin from '../../hooks/useSocialLogin/useSocialLogin'
import useSuccessUrl from '../../hooks/useSuccessUrl/useSuccessUrl'

type Props = {
  setError: (error?: string) => void
}

type SuccessFields = {
  email: string
  name?: string
}

const Google = ({ setError }: Props) => {
  const { searchParams } = useLocation()
  const { track } = useTracking()
  const translate = useTranslate('auth.select_type')
  const { googleTagManagerTrack } = useGoogleTagManagerTrack()
  const [googleToken, setGoogleToken] = useState('')
  const [googleFields, setGoogleFields] = useState<SuccessFields>()
  const [decodedUrl, setDecodedUrl] = useState<string>()
  const successUrl = useSuccessUrl()
  const authenticateSocial = useSocialLogin()
  const { handleViewExternalRegister, handleViewTwoFactorLogin } = useAuthenticationContext()
  const { trackClickEvent } = useAuthTracking()

  function handleLoginWithoutEmail(controlCode: string, email?: string) {
    handleViewExternalRegister({
      view: AuthExternalRegisterView.PasswordVerification,
      data: {
        idToken: controlCode,
        email,
      },
    })
  }

  const handleButtonClick = () => {
    trackClickEvent({ target: ClickableElement.LoginWithGoogle })
  }

  const handleSuccess = async ({
    token,
    fields: { email, name },
    redirectUrl,
  }: OnGoogleLoginSuccess) => {
    setGoogleToken(token)
    setGoogleFields({ email, name })
    setDecodedUrl(redirectUrl)

    const response = await authenticateSocial(AuthenticateProvider.Google, token)
    const is2FARequired =
      response.code === ResponseCode.Required2FA ||
      response.code === ResponseCode.VerifierSecondFactorRequired

    if ('errors' in response) {
      // TODO: handle ResponseCode.SessionFromTokenError
      if (response.code === ResponseCode.NotFound) {
        handleViewExternalRegister({
          view: AuthExternalRegisterView.GoogleRegister,
          data: {
            idToken: token,
            realName: name,
            email,
          },
        })
      } else if (
        response.code === ResponseCode.LoginWithoutEmail &&
        response.payload &&
        'control_code' in response.payload
      ) {
        handleLoginWithoutEmail(response.payload.control_code, email)
      } else if (is2FARequired && response.payload) {
        handleViewTwoFactorLogin({
          ...transformAuthenticateUserError(response.payload),
          refUrl: successUrl,
        })
      } else {
        setError(response.message)
      }

      return
    }

    const getCurrentUserResp = await getCurrentUser()
    const userId = 'errors' in getCurrentUserResp ? undefined : getCurrentUserResp.user.id

    googleTagManagerTrack(GoogleTagManagerEvent.Login, {
      auth_type: 'google',
      user_email: email,
    })
    track(authenticateSuccessEvent({ type: 'google', userId }))
    navigateToPage(redirectUrl || successUrl)
  }

  useDataDomeCaptcha(() => {
    if (!googleToken || !googleFields) return

    setError(undefined)
    handleSuccess({ token: googleToken, fields: googleFields, redirectUrl: decodedUrl })
  })

  const handleFailure = useCallback(() => {
    setError(translate('social_login_error'))
    track(
      authenticateFailEvent({
        type: 'google',
        error: 'User closed screen or did not provide permissions',
      }),
    )
  }, [track, setError, translate])

  return (
    <GoogleOauthButton
      onSuccess={handleSuccess}
      onFailure={handleFailure}
      onClick={handleButtonClick}
      redirectUri={urlWithParams(successUrl, searchParams)}
    />
  )
}

export default Google
