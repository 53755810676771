'use client'

import { ReactNode, useEffect } from 'react'

import { ErrorBoundary } from '@marketplace-web/shared/ui-helpers'
import { useLocation } from '@marketplace-web/shared/browser'

import useAuthenticationContext from '../../hooks/useAuthenticationContext'
import { incrementPageLoadInitiatedCounter, handlePageLoadFailure } from '../../observability/utils'
import { ComponentLocation, ErrorReason } from '../../observability/constants'

type Props = {
  children: ReactNode
  componentLocation: ComponentLocation
}

const AuthenticationErrorBoundary = ({ children, componentLocation }: Props) => {
  const { authView } = useAuthenticationContext()
  const { relativeUrl } = useLocation()

  useEffect(() => {
    incrementPageLoadInitiatedCounter({ authView, componentLocation, pathname: relativeUrl })
  }, [authView, componentLocation, relativeUrl])

  const handleError = (error: Error | null) => {
    handlePageLoadFailure({
      error,
      authView,
      componentLocation,
      reason: ErrorReason.ErrorBoundary,
      pathname: relativeUrl,
    })
  }

  return (
    <ErrorBoundary
      onError={handleError}
      FallbackComponent={ErrorBoundary.ComponentError}
      preventLog
    >
      {children}
    </ErrorBoundary>
  )
}

export default AuthenticationErrorBoundary
