'use client'

import { Component, ReactNode } from 'react'
import { IntlShape, injectIntl } from 'react-intl'
import { Button, EmptyState } from '@vinted/web-ui'

type Props = {
  title?: ReactNode
  body?: ReactNode
  ctaText?: ReactNode
  ctaLoading?: boolean
  onCtaClick?: () => void
  intl: IntlShape
}

class ErrorState extends Component<Props> {
  constructor(props: Props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  translate = (id: string) => this.props.intl.formatMessage({ id: `error_state.${id}` })

  handleClick(event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) {
    const { onCtaClick: onCta, ctaLoading } = this.props

    if (ctaLoading) {
      event.preventDefault()

      return
    }

    onCta?.()
  }

  renderCta() {
    const { ctaText, onCtaClick: onCta, ctaLoading } = this.props

    if (!onCta) return undefined

    return (
      <Button
        isLoading={ctaLoading}
        text={ctaText || this.translate('cta')}
        styling={Button.Styling.Filled}
        onClick={this.handleClick}
      />
    )
  }

  render() {
    const { title, body } = this.props

    return (
      <EmptyState
        title={title || this.translate('title')}
        body={body || this.translate('body')}
        action={this.renderCta()}
      />
    )
  }
}

export default injectIntl(ErrorState)
