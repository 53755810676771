'use client'

import { useEffect } from 'react'
import { kebabCase } from 'lodash'

import { ErrorState } from '@marketplace-web/shared/ui-helpers'
import { isValueInObject } from '@marketplace-web/shared/utils'

import { useLocation } from '@marketplace-web/shared/browser'

import PasswordVerification from '../PasswordVerificationLogin'
import { AuthExternalRegisterView, AuthView } from '../../constants'
import SelectType from '../SelectType'
import EmailLogin from '../EmailLogin'
import EmailRegister from '../EmailRegister'
import ResetPassword from '../ResetPassword'
import FacebookRegister from '../FacebookRegister'
import GoogleRegister from '../GoogleRegister'
import AppleRegister from '../AppleRegister'
import LoginTwoFactorVerification from '../LoginTwoFactorVerification'
import useAuthTracking from '../../hooks/useAuthTracking/useAuthTracking'
import useAuthenticationContext from '../../hooks/useAuthenticationContext'
import { handlePageLoadFailure } from '../../observability/utils'
import { ComponentLocation, ErrorReason } from '../../observability/constants'

const componentByAuthView: Record<
  AuthView,
  (props: { isAuthPage?: boolean }) => JSX.Element | null
> = {
  [AuthView.SelectTypeRegister]: SelectType,
  [AuthView.SelectTypeLogin]: SelectType,
  [AuthView.EmailLogin]: EmailLogin,
  [AuthView.EmailRegister]: EmailRegister,
  [AuthView.ResetPassword]: ResetPassword,
  [AuthView.TwoFactorLogin]: LoginTwoFactorVerification,
}

const componentByAuthExternalRegisterView: Record<
  AuthExternalRegisterView,
  () => JSX.Element | null
> = {
  [AuthExternalRegisterView.FacebookRegister]: FacebookRegister,
  [AuthExternalRegisterView.GoogleRegister]: GoogleRegister,
  [AuthExternalRegisterView.AppleRegister]: AppleRegister,
  [AuthExternalRegisterView.PasswordVerification]: PasswordVerification,
}

type Props = {
  componentLocation?: ComponentLocation
}

const Auth = ({ componentLocation }: Props) => {
  const { authView, externalRegisterData } = useAuthenticationContext()
  const { trackViewScreen } = useAuthTracking()
  const location = useLocation()

  useEffect(() => {
    trackViewScreen()
  }, [trackViewScreen])

  const renderViewWrapper = (children: JSX.Element) => (
    <span data-testid={`${kebabCase(authView)}-view`}>{children}</span>
  )

  if (isValueInObject(authView, AuthView)) {
    const Component = componentByAuthView[authView]

    return renderViewWrapper(<Component />)
  }

  if (!externalRegisterData) {
    if (componentLocation) {
      handlePageLoadFailure({
        authView,
        componentLocation,
        reason: ErrorReason.UnresolvedComponent,
        pathname: location.relativeUrl,
      })
    }

    return <ErrorState />
  }

  const Component = componentByAuthExternalRegisterView[authView]

  return renderViewWrapper(<Component />)
}

export default Auth
