'use client'

import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { Button } from '@vinted/web-ui'
import { produce } from 'immer'
import { noop } from 'lodash'
import { decodeJwt } from 'jose'

import { useEnvs } from '@marketplace-web/shared/environment'
import { loadScript } from '@marketplace-web/shared/utils'

import { initAppleId } from '../../utils/apple'
import { AppleIDLoginStatus } from '../../constants'

const AppleIcon = () => (
  <div className="u-flexbox u-padding-small">
    <svg fill="none" viewBox="0 0 24 24" width="20" height="20">
      <path
        fill="#000"
        d="M16.387 5.807c.227 0 .614.036 1.16.108.547.072 1.145.278 1.795.619.66.33 1.259.882 1.795 1.656a7.7 7.7 0 0 1-.448.34c-.269.186-.568.464-.898.836-.33.36-.619.825-.866 1.392-.248.557-.372 1.223-.372 1.996 0 .888.155 1.64.464 2.26.32.619.686 1.119 1.1 1.5.422.372.793.645 1.113.82l.526.28c-.01.04-.144.407-.402 1.098-.248.691-.66 1.46-1.238 2.305a16.74 16.74 0 0 1-1.64 2.027c-.578.62-1.274.929-2.09.929-.546 0-.995-.077-1.345-.232a26.55 26.55 0 0 0-1.084-.48c-.37-.165-.871-.247-1.5-.247-.61 0-1.12.082-1.532.247-.403.165-.79.33-1.161.495-.361.165-.79.248-1.284.248-.753 0-1.414-.3-1.981-.898a20.352 20.352 0 0 1-1.749-2.15c-.69-.99-1.284-2.198-1.779-3.621a13.45 13.45 0 0 1-.727-4.333c0-1.558.294-2.863.882-3.915.588-1.062 1.34-1.862 2.259-2.398.928-.547 1.888-.82 2.878-.82a4.16 4.16 0 0 1 1.486.263c.464.165.897.335 1.3.51.412.176.783.263 1.114.263.32 0 .69-.093 1.114-.278.423-.186.897-.366 1.423-.542a5.031 5.031 0 0 1 1.687-.278Zm-.851-1.966a5.103 5.103 0 0 1-1.517 1.223c-.608.32-1.186.48-1.733.48-.113 0-.221-.01-.325-.031a2.126 2.126 0 0 1-.046-.433c0-.62.134-1.218.402-1.796.268-.588.573-1.072.913-1.454.433-.516.98-.944 1.64-1.285.66-.34 1.29-.52 1.888-.541.031.134.047.294.047.48a4.8 4.8 0 0 1-.356 1.81 6.06 6.06 0 0 1-.913 1.547Z"
      />
    </svg>
  </div>
)

type Props = {
  text: ReactNode
  isLoading?: boolean
  onClick?: () => void
  onSuccess: (detail: AppleSignInAPI.SignInResponseI) => void
  onFailure?: () => void
}

const APPLE_SDK_URL =
  'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'

const AppleButton = ({ text, isLoading, onClick = noop, onSuccess, onFailure = noop }: Props) => {
  const [isLoadingAppleID, setIsLoadingAppleID] = useState(false)
  const [isInitialized, setIsInitialized] = useState(false)
  const clientId = useEnvs('APPLE_CLIENT_ID')

  const onSuccessRef = useRef(onSuccess)
  onSuccessRef.current = onSuccess

  const onFailureRef = useRef(onFailure)
  onFailureRef.current = onFailure

  const handleAppleSignInSuccess = useCallback((event: Event) => {
    const { detail } = event as CustomEvent<AppleSignInAPI.SignInResponseI>
    const { email, is_private_email = false } = decodeJwt<{
      email: string
      is_private_email: boolean
    }>(detail.authorization.id_token)

    const newDetail = produce(detail, draft => {
      if (is_private_email || !email || draft.user) return

      draft.user = {
        name: {
          firstName: '',
          lastName: '',
        },
        email,
      }
    })

    onSuccessRef.current(newDetail)
    setIsLoadingAppleID(false)
  }, [])

  const handleAppleSignInFailure = useCallback(() => {
    onFailureRef.current()
    setIsLoadingAppleID(false)
  }, [])

  useEffect(() => {
    document.addEventListener(AppleIDLoginStatus.Success, handleAppleSignInSuccess)
    document.addEventListener(AppleIDLoginStatus.Failure, handleAppleSignInFailure)

    return () => {
      document.removeEventListener(AppleIDLoginStatus.Success, handleAppleSignInSuccess)
      document.removeEventListener(AppleIDLoginStatus.Failure, handleAppleSignInFailure)
    }
  }, [handleAppleSignInFailure, handleAppleSignInSuccess])

  useEffect(() => {
    async function initialize() {
      if (!clientId) return

      await loadScript({
        id: 'apple-sdk',
        isAsync: true,
        src: APPLE_SDK_URL,
      })

      initAppleId(clientId)
      setIsInitialized(true)
    }

    initialize()
  }, [clientId])

  function handleClick(event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) {
    if (isLoading || isLoadingAppleID) {
      event.preventDefault()

      return
    }

    onClick()
    setIsLoadingAppleID(true)

    window.AppleID?.auth.signIn()
  }

  if (!clientId) return null

  return (
    <Button
      theme="amplified"
      onClick={handleClick}
      disabled={!isInitialized || isLoading || isLoadingAppleID}
      isLoading={isLoading || isLoadingAppleID}
      // TODO: Replace with AppleLogo24 from @vinted/multichrome-icons when it's updated
      icon={<AppleIcon />}
      text={text}
    />
  )
}

export default AppleButton
