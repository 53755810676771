import { ApiResponse, ResponseError } from '@marketplace-web/shared/api-client'

const PASSWORD_CHANGE_RESTRICTED_ERROR =
  'This account has a special role, so for security reasons it can’t have a password.'

export const isNetworkError = (response: Record<string, unknown> | ResponseError<unknown>) => {
  if (!('errors' in response)) return false

  return response.code === 'ERR_NETWORK'
}

export const isTimeoutError = (response: Record<string, unknown> | ResponseError<unknown>) => {
  if (!('errors' in response)) return false

  return response.code === 'ETIMEDOUT'
}

export const isPasswordChangeRestricted = (response: ApiResponse | ResponseError<unknown>) => {
  if (!('errors' in response)) return false

  return response.errors.some(({ value }) => value === PASSWORD_CHANGE_RESTRICTED_ERROR)
}
